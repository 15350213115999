import * as React from "react";
import { useLocation } from "react-router";
import { parseSearchString } from "../../helpers/utils";

const useCurrentSearch = () => {
  const location = useLocation();
  const search = React.useMemo(() => parseSearchString(location.search), [location.search]);
  return search;
};

export default useCurrentSearch;
