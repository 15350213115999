import { Breadcrumbs, Chip } from "@mui/material";
import { groupBy, isEqual, map, uniqWith } from "lodash-es";
import * as React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import { FontAwesomeIcon } from "#components/index.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import * as styles from "./styles/index.scss";

interface ClassBreadcrumbs {
  rootClass: string;
  rootClassLabel?: string;
  midClassA: string;
  midClassALabel?: string;
  midClassB: string;
  midClassBLabel: string;
  currentClass: string;
  currentClassLabel?: string;
}

const factory = factories.compliant;

const DIRECT = "^rdfs:subClassOf";
const INDIRECT = "(^rdfs:subClassOf)*";
const sparqlFetchCurrentResourceClassPaths = (resource: string) => `
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>

select ?rootClass ?midClassA ?midClassB ?currentClass (sample(?currentClassl) as ?currentClassLabel) (sample(?midClassAL) as ?midClassALabel) (sample(?midClassBL) as ?midClassBLabel) (sample(?rootClassl) as ?rootClassLabel)  
where {
  bind(${termToString(factory.namedNode(resource))} as ?currentClass)
  ?rootClass ${INDIRECT} ?counter .
  ?counter ${INDIRECT} ?midClassA .
  FILTER NOT EXISTS { [] ${DIRECT} ?rootClass }

  ?midClassA ${DIRECT} ?midClassB .

  ?midClassB ${INDIRECT} ?currentClass .
  #FILTER NOT EXISTS { ?currentClass ${DIRECT} [] }

  optional{
    ?currentClass rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm ?currentClassl
  }
  optional{
    ?rootClass rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm ?rootClassl
  }
  optional{
    ?midClassA rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm ?midClassAL
  }
  optional{
    ?midClassB rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm ?midClassBL
  }
}
group by ?rootClass ?currentClass ?midClassA ?midClassB 
order by ?rootClass ?currentClass count(?counter)
`;

const ClassBreadcrumbs: React.FC<{}> = ({}) => {
  const currentClass = useCurrentResource();
  const location = useLocation();
  const applyPrefixes = useApplyPrefixes();

  const { data, error, loading } = useSparql<ClassBreadcrumbs[]>(sparqlFetchCurrentResourceClassPaths(currentClass));

  if (loading || error || !data || data.length === 0) return null;

  const breadcrumbs = data.map((d) => {
    // Removing duplicate mids and mapping into an easier typing interface to read inside breadcrumbs
    let tempCrumbs: { iri: string; label?: string }[] = [];
    tempCrumbs.push({ iri: d.rootClass, label: d.rootClassLabel });
    if (d.midClassA !== d.rootClass) tempCrumbs.push({ iri: d.midClassA, label: d.midClassALabel });
    if (d.midClassB !== d.midClassA) tempCrumbs.push({ iri: d.midClassB, label: d.midClassBLabel });
    if (d.currentClass !== d.midClassB) tempCrumbs.push({ iri: d.currentClass, label: d.currentClassLabel });
    return tempCrumbs;
  });

  // Removing duplicate paths
  const uniqueBreadcrumbs = uniqWith(breadcrumbs, isEqual);

  // Grouping by root nodes and sorting the highest available path
  const groupedUniqueBreadcrumbs = map(
    groupBy(uniqueBreadcrumbs, (crumbs) => crumbs[0].iri),
    (groupedCrumbs) => {
      return groupedCrumbs.sort((a, b) => {
        return b.length - a.length;
      });
    },
  );

  return (
    <>
      {groupedUniqueBreadcrumbs.map((crumbs, index) => {
        return (
          <Breadcrumbs
            separator={<FontAwesomeIcon icon={["fas", "caret-right"]} />}
            maxItems={4}
            className={styles.breadcrumbs}
            key={crumbs[0][0].iri + index}
          >
            {crumbs[0].map((crumb) => {
              if (currentClass !== crumb.iri) {
                return (
                  <Link
                    to={{
                      pathname: location.pathname,
                      search: stringifyQuery({ resource: crumb.iri }),
                    }}
                  >
                    {crumb.label || applyPrefixes(crumb.iri)}
                  </Link>
                );
              } else {
                return <Chip label={crumb.label || applyPrefixes(crumb.iri)} size="small" />;
              }
            })}
          </Breadcrumbs>
        );
      })}
    </>
  );
};

export default ClassBreadcrumbs;
