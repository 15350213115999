export interface EditorProcess {
  statuses: EditorInstanceStatus[];
  actions: EditorInstanceAction[];
}

export interface EditorInstanceStatus {
  name: "unknown" | "unrecognized" | string; // "unknown" and "unrecognized" are reserved for instances without or unrecognized statuses
  color?: MuiColor;
}

export type EditorInstanceAction = InstanceDeleteAction | InstanceEditAction | InstanceEntryAction | UnknownAction;
export type EditorActionType = EditorInstanceAction["type"];
export type MuiColor = "default" | "primary" | "secondary" | "error" | "success" | "warning" | "info";

interface EditorInstanceBaseAction {
  color?: MuiColor;
  name: string;
}
interface InstanceDeleteAction extends EditorInstanceBaseAction {
  type: "delete";
  onState: string[];
}
interface InstanceEditAction extends EditorInstanceBaseAction {
  type: "edit" | "updateStatus";
  onState: string[];
  toState: string;
  forceReview?: boolean;
}
interface InstanceEntryAction extends EditorInstanceBaseAction {
  type: "create";
  toState: string;
}
interface UnknownAction extends EditorInstanceBaseAction {
  type: "unknown";
}

export const SIMPLE_PROCESS: EditorProcess = {
  statuses: [],
  actions: [
    {
      name: "Create",
      type: "create",
      toState: "unknown",
      color: "primary",
    },
    {
      name: "Edit",
      type: "edit",
      onState: ["unknown", "unrecognized"],
      toState: "unknown",
      color: "secondary",
    },
    {
      name: "Delete",
      type: "delete",
      onState: ["unknown"],
      color: "error",
    },
  ],
};
export const COMPLEX_PROCESS: EditorProcess = {
  statuses: [
    {
      name: "Draft",
      color: "primary",
    },
    {
      name: "Staged",
      color: "warning",
    },
    {
      name: "Accepted",
      color: "success",
    },
    {
      name: "Deprecated",
      color: "default",
    },
  ],
  actions: [
    {
      name: "Import",
      type: "unknown",
      color: "info",
    },
    {
      name: "Create",
      type: "create",
      toState: "Draft",
      color: "primary",
    },
    {
      name: "Edit",
      type: "edit",
      onState: ["Draft", "Accepted", "Deprecated", "unknown", "unrecognized"],
      toState: "Draft",
      color: "secondary",
    },
    {
      name: "Stage",
      type: "updateStatus",
      onState: ["Draft"],
      toState: "Staged",
      color: "warning",
    },
    {
      name: "Reject",
      type: "updateStatus",
      onState: ["Staged"],
      toState: "Draft",
      color: "warning",
    },
    {
      name: "Accept",
      type: "updateStatus",
      onState: ["Staged"],
      toState: "Accepted",
      color: "success",
      forceReview: true,
    },
    {
      name: "Revoke",
      type: "updateStatus",
      onState: ["Accepted"],
      toState: "Deprecated",
      color: "default",
    },
    {
      name: "Delete",
      type: "delete",
      onState: ["Draft"],
      color: "error",
    },
  ],
};
