import { extname } from "path";
import * as url from "url";
import { NtriplyTerm } from "./Models.js";

export const HDT_MIN_STATEMENTS = 1 as const;
export const HDT_MAX_STATEMENTS = 10000 as const;
export const SPARQL_SERVICE_TYPES = ["virtuoso", "jena", "blazegraph"] as const;
export const ELASTICSEARCH_APIS = ["_search", "_msearch", "_count", "_terms_enum"] as const;
export const SPARQL_SERVICE_TYPES_INCLUDING_SPEEDY = ["speedy", ...SPARQL_SERVICE_TYPES] as const;
export const SERVICE_TYPES = [...SPARQL_SERVICE_TYPES, "elasticSearch"] as const;
export const QUERY_TYPES = ["sparql", "search"] as const;
export const MAX_EXAMPLE_RESOURCES = 30 as const;
export const MAX_DATASET_NAME_LENGTH = 40 as const;
export const MAX_ACCOUNT_NAME_LENGTH = 40 as const;
export const MAX_SERVICE_NAME_LENGTH = 40 as const;
export const RESERVED_SERVICE_NAMES = ["Speedy"];
export const MAX_QUERY_NAME_LENGTH = 40 as const;
export const MAX_STORY_NAME_LENGTH = 40 as const;
export const MAX_GRAPHS_ON_LANDING_PAGE = 10 as const; // specify the number of graphs shown on the landing page

export const SERVICE_GRAPHS_PAGE_SIZE = 30 as const;

export const FACETED_SEARCH_PAGE_SIZE = 50 as const;
export const FACETED_SEARCH_NULL_ALIAS = "Unset" as const;

export const BACKWARD_SUBJECTS_PAGE_SIZE = 40 as const;
export const FORWARD_OBJECT_PAGE_SIZE = 10 as const;
export const BACKWARD_PREDICATES_PAGE_SIZE = 10 as const;
export const FORWARD_PREDICATES_PAGE_SIZE = 50 as const;
export const RESERVED_SPARQL_VARNAMES = ["page", "pageSize", "pageMeta"];
/**
 * Maximum number of rows returned by Virtuoso for a SELECT query.
 * For a CONSTRUCT query, the maximum is exactly 1 higher.
 * (https://github.com/openlink/virtuoso-opensource/pull/940)
 *
 * Changing the value here also configures Virtuoso.
 * The virtuoso default is 10_000.
 *
 * Page size limit: https://issues.triply.cc/issues/4692
 * Construct queries: https://issues.triply.cc/issues/4788
 */
export const VIRTUOSO_SPARQL_RESULT_SET_MAX_ROWS = 10_000 as const;
export const SPARQL_RESULT_DEFAULT_PAGE_SIZE = 100 as const;
export const SPARQL_RETRY_CONSTRUCT_SHRINK_FACTOR = 0.8 as const;

export const AVATAR_SIZE_LIMIT = "5mb" as const;

// file upload error messages (used in both console and api)
export const GENERIC_FILE_UPLOAD_ERROR_MESSAGE = "An error occurred while uploading the file." as const;
export const DUPLICATE_ASSETNAME_ERROR_MESSAGE = "An asset with this name has already been uploaded." as const;

export const CANT_CREATE_ZERO_GRAPH_SERVICE_MESSAGE = "Cannot create a service for a dataset without graphs.";

export const NO_ACCOUNTS_LEFT_MESSAGE = "There are currently no more available accounts.";
export const SUBSCRIPTIONS_PAGE = "https://triply.cc/subscriptions";

export const SPARQL_VARNAME_CHARS =
  "[0-9a-zA-Z_\u00C0-\u00D6\u00D8-\u00F6\u0370-\u037d\u037F-\u1FFF\u200C-\u200D\u2070-\u218F\u2C00-\u2FEF\u3001-\uD7FF\uF900-\uFDCF\uFDF0-\uFFFD]";

export const IDE_SPARQL_ACCEPT_HEADER = "application/sparql-results+json, application/n-triples";

// prettier-ignore
export const SUPPORTED_EXTENSIONS = [

  // ## archive/compressed formats.
  "gz", "bz2", "xz", "tar", "zip", "tgz",

  // ## JSON-LD
  // https://www.w3.org/TR/json-ld11/ and parser is : https://github.com/rubensworks/jsonld-streaming-parser.js
  "jsonld",
  "json",

  // ## rdf-xml
  // https://www.w3.org/TR/rdf-syntax-grammar/ :      It is recommended that RDF/XML files have the extension ".rdf" (all lowercase) on all platforms.
  "rdf",
  // https://issues.triply.cc/issues/3450 : rdfs is commonly used to denote rdf-xml data
  "rdfs",
  // https://www.w3.org/TR/owl-xml-serialization:     It is recommended that OWL XML Serialization files have the extension .owx (all lowercase) on all platforms.
  // https://www.w3.org/TR/owl2-xml-serialization/:   It is recommended that OWL XML Serialization files have the extension .owx (all lowercase) on all platforms.
  'owx',
  //  including .owl since it's very common
  'owl',

  // ## turtle family
  // https://www.w3.org/TR/n-triples/ :               It is recommended that N-Triples files have the extension ".nt" (all lowercase) on all platforms.
  "nt",
  // https://www.w3.org/TR/turtle/ :                  It is recommended that Turtle files have the extension ".ttl" (all lowercase) on all platforms.
  "ttl",
  // https://www.w3.org/TR/n-quads/ :                 It is recommended that N-Quads files have the extension ".nq" (all lowercase) on all platforms.
  "nq",
  // https://www.w3.org/TR/trig/ :                    It is recommended that TriG files have the extension ".trig" (all lowercase) on all platforms.
  "trig",
  // https://www.w3.org/TeamSubmission/n3/ :          It is recommended that Notation3 files have the extension ".n3" (all lowercase) on all platforms.
  "n3",

  // Delimited formats
  // Mapping from CSV/TSV to RDF is defined here: https://issues.triply.cc/issues/5213
  "csv",
  "tsv",

  /**
   * XML datasets
   */
  "xml",
  "gpx", // https://en.wikipedia.org/wiki/GPS_Exchange_Format
] as const;
export type SupportedExtension = (typeof SUPPORTED_EXTENSIONS)[number];

const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".svg", ".bmp", ".tiff"];
export interface ObjectFilter<T> {
  value?: string;
  language?: string;
  datatype?: string;
  termType?: NtriplyTerm["termType"];
  validationFunction?: (term: T) => boolean;
}

export type QueryPattern<T extends { termType: NtriplyTerm["termType"] }> = [
  string?,
  ObjectFilter<T>?,
  string?,
  ObjectFilter<T>?,
];

export const RESOURCE_WIDGET_PATTERNS = {
  IMAGE_PATTERNS: <QueryPattern<NtriplyTerm>[]>[
    ["http://xmlns.com/foaf/0.1/depiction", null, "http://xmlns.com/foaf/0.1/thumbnail", null],
    ["https://schema.org/image", null, "https://schema.org/contentUrl"],
    ["https://schema.org/thumbnail", null, "https://schema.org/contentUrl"],
    ["http://www.w3.org/1999/02/22-rdf-syntax-ns#type", { value: "https://schema.org/ImageObject" }],
    ["http://xmlns.com/foaf/0.1/depiction", null],
    ["http://xmlns.com/foaf/0.1/thumbnail", null],
    ["https://schema.org/image"],
    [null, { datatype: "https://triply.cc/triply/def/imageURI" }],
    [
      null,
      {
        datatype: "http://www.w3.org/2001/XMLSchema#anyURI",
        validationFunction: (term: NtriplyTerm) => {
          try {
            const parsedTerm = url.parse(term.value);
            return (
              imageExtensions.indexOf(extname(parsedTerm.pathname || "").toLowerCase()) >= 0 ||
              imageExtensions.indexOf(extname(parsedTerm.search || "").toLowerCase()) >= 0
            );
          } catch (e: unknown) {
            return false;
          }
        },
      },
    ],
  ],
  LABEL_PATTERNS: <QueryPattern<NtriplyTerm>[]>[
    ["http://www.w3.org/2000/01/rdf-schema#label", { language: "en" }],
    ["http://www.w3.org/2000/01/rdf-schema#label", undefined],
    ["http://xmlns.com/foaf/0.1/name", { language: "en" }],
    ["http://xmlns.com/foaf/0.1/name", undefined],
    ["https://schema.org/name", { language: "en" }],
    ["https://schema.org/name", undefined],
    ["http://www.w3.org/2004/02/skos/core#prefLabel", { language: "en" }],
    ["http://www.w3.org/2004/02/skos/core#prefLabel", undefined],
    [
      "http://www.w3.org/2008/05/skos-xl#prefLabel",
      undefined,
      "http://www.w3.org/2008/05/skos-xl#literalForm",
      { language: "en" },
    ],
    ["http://www.w3.org/2008/05/skos-xl#prefLabel", undefined, "http://www.w3.org/2008/05/skos-xl#literalForm"],
    ["http://www.w3.org/2008/05/skos-xl#literalForm", { language: "en" }],
    ["http://www.w3.org/2008/05/skos-xl#literalForm", undefined],
    ["http://purl.org/dc/terms/title", { language: "en" }],
    ["http://purl.org/dc/terms/title", undefined],
    ["http://purl.org/dc/elements/1.1/title", { language: "en" }],
    ["http://purl.org/dc/elements/1.1/title", undefined],
    ["http://www.w3.org/ns/shacl#name", { language: "en" }],
    ["http://www.w3.org/ns/shacl#name", undefined],
  ],
  GEO_PATTERNS: <QueryPattern<NtriplyTerm>[]>[
    ["http://www.opengis.net/ont/geosparql#asWKT", undefined],
    ["http://www.opengis.net/ont/geosparql#hasGeometry", undefined, "http://www.opengis.net/ont/geosparql#asWKT"],
  ],
  LAT_PATTERNS: <QueryPattern<NtriplyTerm>[]>[["http://www.w3.org/2003/01/geo/wgs84_pos#lat", undefined]],
  LONG_PATTERNS: <QueryPattern<NtriplyTerm>[]>[["http://www.w3.org/2003/01/geo/wgs84_pos#long", undefined]],
  AUDIO_PATTERNS: <QueryPattern<NtriplyTerm>[]>[
    ["https://schema.org/audio"],
    ["http://www.w3.org/1999/02/22-rdf-syntax-ns#type", { value: "https://schema.org/AudioObject" }],
  ],
  VIDEO_PATTERNS: <QueryPattern<NtriplyTerm>[]>[
    ["https://schema.org/video"],
    ["http://www.w3.org/1999/02/22-rdf-syntax-ns#type", { value: "https://schema.org/VideoObject" }],
  ],
  MEDIA_PATTERNS: <QueryPattern<NtriplyTerm>[]>[["https://schema.org/contentUrl"]],
  MEDIA_ENCODING_PATTERNS: <QueryPattern<NtriplyTerm>[]>[["https://schema.org/encodingFormat"]],
};

export interface DatasetLicense {
  url: string;
  gui_name: string;
  version: string;
  acronym: string;
  deprecated: boolean;
}

export interface Licenses {
  "CC0 1.0": DatasetLicense;
  "CC BY": DatasetLicense;
  "CC BY-ND": DatasetLicense;
  "CC BY-NC": DatasetLicense;
  "CC BY-NC-ND": DatasetLicense;
  "CC BY-NC-SA": DatasetLicense;
  "CC BY-SA v4.0": DatasetLicense;
  "ODC-By": DatasetLicense;
  "ODC-ODbL": DatasetLicense;
  PDDL: DatasetLicense;
  GFDL: DatasetLicense;
  "CC-BY-SA": DatasetLicense;
}

export type DatasetLicenseId = keyof Licenses;

export const LICENSES = {
  "CC0 1.0": {
    url: "https://creativecommons.org/publicdomain/zero/1.0/",
    version: "v1.0",
    acronym: "CC0 1.0",
    gui_name: "CC0 1.0 Universal",
    deprecated: false,
  },
  "CC BY": {
    url: "https://creativecommons.org/licenses/by/4.0/",
    version: "v4.0",
    acronym: "CC BY",
    gui_name: "Attribution 4.0 International",
    deprecated: false,
  },
  "CC BY-ND": {
    url: "https://creativecommons.org/licenses/by-nd/4.0/",
    version: "v4.0",
    acronym: "CC BY-ND",
    gui_name: "Attribution-NoDerivatives 4.0 International",
    deprecated: false,
  },
  "CC BY-NC": {
    url: "https://creativecommons.org/licenses/by-nc/4.0/",
    version: "v4.0",
    acronym: "CC BY-NC",
    gui_name: "Attribution-NonCommercial 4.0 International",
    deprecated: false,
  },
  "CC BY-NC-ND": {
    url: "https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode",
    version: "v4.0",
    acronym: "CC BY-NC-ND",
    gui_name: "Attribution-NonCommercial-NoDerivatives 4.0 International",
    deprecated: false,
  },
  "CC BY-NC-SA": {
    url: "https://creativecommons.org/licenses/by-nc-sa/4.0/",
    version: "v4.0",
    acronym: "CC BY-NC-SA",
    gui_name: "Attribution-NonCommercial-ShareAlike 4.0 International",
    deprecated: false,
  },
  "CC BY-SA v4.0": {
    url: "https://creativecommons.org/licenses/by-sa/4.0/",
    version: "v4.0",
    acronym: "CC BY-SA v4.0",
    gui_name: "Attribution-ShareAlike 4.0 International",
    deprecated: false,
  },
  "ODC-By": {
    url: "https://opendatacommons.org/licenses/by/1-0/",
    version: "v1.0",
    acronym: "ODC-By",
    gui_name: "Open Data Commons Attribution License 1.0",
    deprecated: false,
  },
  "ODC-ODbL": {
    url: "https://opendatacommons.org/licenses/odbl/1-0/",
    version: "v1.0",
    acronym: "ODC-ODbL",
    gui_name: "Open Data Commons Open Database License 1.0",
    deprecated: false,
  },
  PDDL: {
    url: "https://opendatacommons.org/licenses/pddl/1-0/",
    version: "v1.0",
    acronym: "PDDL",
    gui_name: "Open Data Commons Public Domain Dedication and License 1.0",
    deprecated: false,
  },
  "CC-BY-SA": {
    url: "https://creativecommons.org/licenses/by-sa/3.0/",
    version: "v3.0",
    acronym: "CC BY-SA",
    gui_name: "Attribution-ShareAlike 3.0 International",
    deprecated: true,
  },
  GFDL: {
    url: "https://www.gnu.org/licenses/fdl-1.3.html",
    version: "v1.3",
    acronym: "GFDL",
    gui_name: "GNU Free Documentation License",
    deprecated: true,
  },
} as const satisfies Licenses;

export const LicenseNames = [...(Object.keys(LICENSES) as DatasetLicenseId[]), null];

export const INSIGHTS_IRI_FOR_CLASS_UNKNOWN = "UNKNOWN:unknown" as const;

export const DATATYPE_DEFINITIONS: {
  [dataType: string]: {
    label: string;
    prefixed: string;
    description?: string;
    examples: { value: string; textual?: string }[];
  };
} = {
  "http://www.w3.org/2001/XMLSchema#boolean": {
    label: "boolean",
    prefixed: "xsd:boolean",
    examples: [{ value: "true" }, { value: "false" }, { value: "0" }, { value: "1" }],
  },
  "http://www.w3.org/2001/XMLSchema#decimal": {
    label: "decimal",
    prefixed: "xsd:decimal",
    examples: [{ value: "1.23" }, { value: "-234.456" }, { value: "+10000.00" }],
  },
  "http://www.w3.org/2001/XMLSchema#float": {
    label: "float",
    prefixed: "xsd:float",
    examples: [{ value: "-1e4" }, { value: "1267.43233E12" }, { value: "12.78e-2" }],
  },
  "http://www.w3.org/2001/XMLSchema#double": {
    label: "double",
    prefixed: "xsd:double",
    examples: [{ value: "-1e4" }, { value: "1267.43233E12" }, { value: "12.78e-2" }],
  },
  "http://www.w3.org/2001/XMLSchema#duration": {
    label: "duration",
    prefixed: "xsd:duration",
    examples: [{ value: "P1Y2M3DT10H30M", textual: "1 year, 2 months, 3 days, 10 hours, and 30 minutes" }],
  },
  "http://www.w3.org/2001/XMLSchema#dateTime": {
    label: "date-time",
    prefixed: "xsd:dateTime",
    examples: [{ value: "2005-10-14T12:00:00-05:00", textual: "14 October 2005, Eastern Standard Time" }],
  },
  "http://www.w3.org/2001/XMLSchema#time": {
    label: "time",
    prefixed: "xsd:time",
    examples: [{ value: "13:20:00-05:00", textual: "1:20 pm Eastern Standard Time" }],
  },
  "http://www.w3.org/2001/XMLSchema#date": {
    label: "date",
    prefixed: "xsd:date",
    examples: [{ value: "2002-10-14", textual: "October 14th, 2002" }],
  },
  "http://www.w3.org/2001/XMLSchema#gYearMonth": {
    label: "year-month",
    prefixed: "xsd:yearMonth",
    examples: [
      { value: "1999-05", textual: "May 1999" },
      { value: "2026-12", textual: "December 2026" },
    ],
  },
  "http://www.w3.org/2001/XMLSchema#gMonthDay": {
    label: "month-day",
    prefixed: "xsd:gMonthDay",
    examples: [{ value: "--04-16", textual: "April 16th" }],
  },
  "http://www.w3.org/2001/XMLSchema#gDay": {
    label: "day",
    prefixed: "xsd:day",
    examples: [{ value: "---16", textual: "16th" }],
  },
  "http://www.w3.org/2001/XMLSchema#gMonth": {
    label: "month",
    prefixed: "xsd:gMonth",
    examples: [{ value: "--12", textual: "December" }],
  },
  "http://www.w3.org/2001/XMLSchema#gYear": {
    label: "year",
    prefixed: "xsd:gYear",
    examples: [{ value: "1999" }, { value: "2021" }, { value: "2026" }],
  },
  "http://www.w3.org/2001/XMLSchema#hexBinary": {
    label: "hexBinary",
    prefixed: "xsd:hexBinary",
    examples: [
      {
        value: "0FB7",
        textual: "hex encoding for the 16-bit integer 4023, which has a binary representation of 111110110111",
      },
    ],
  },
  "http://www.w3.org/2001/XMLSchema#base64Binary": {
    label: "base64Binary",
    prefixed: "xsd:base64Binary",
    description: `The lexical form of base64Binary is limited to the 65 characters of the base64 alphabet that is defined in RFC
      2045. Valid characters include a-z, A-Z, 0-9, the plus sign (+), the forward slash (/), the equal sign (=), and
      the characters defined in XML 1.0 (Third Edition) as white space. No other characters are allowed.`,
    examples: [
      {
        value: "aGVyb25neWFuZy5jb20=",
      },
    ],
  },
  "http://www.w3.org/2001/XMLSchema#int": {
    label: "int",
    prefixed: "xsd:int",
    description: `Represents an integer that is less than or equal to 2 147 483 647 and greater than or equal to -2 147 483 648.`,
    examples: [{ value: "-1" }, { value: "0" }, { value: "126789675" }, { value: "+100000" }],
  },
  "http://www.w3.org/2001/XMLSchema#integer": {
    label: "integer",
    prefixed: "xsd:integer",
    description: `Represents a number that is less than or equal to 9 223 372 036 854 775 807 and greater than or equal to -9 223
      372 036 854 775 808.`,
    examples: [{ value: "-1" }, { value: "0" }, { value: "126789675" }, { value: "+100000" }],
  },
  "http://www.w3.org/2001/XMLSchema#long": {
    label: "long",
    prefixed: "xsd:long",
    description: `Represents an integer that is less than or equal to 9 223 372 036 854 775 807 and greater than or equal to -9 223
      372 036 854 775 808.`,
    examples: [{ value: "-1" }, { value: "0" }, { value: "12678967543233" }, { value: "+100000" }],
  },
  "http://www.w3.org/2001/XMLSchema#negativeInteger": {
    label: "negative integer",
    prefixed: "xsd:negativeInteger",
    examples: [{ value: "-1" }, { value: "-12678967543233" }, { value: "-100000" }],
  },
  "http://www.w3.org/2001/XMLSchema#nonNegativeInteger": {
    label: "non-negative integer",
    prefixed: "xsd:nonNegativeInteger",
    examples: [{ value: "1" }, { value: "0" }, { value: "12678967543233" }, { value: "+100000" }],
  },
  "http://www.w3.org/2001/XMLSchema#nonPositiveInteger": {
    label: "non-positive integer",
    prefixed: "xsd:nonPositiveInteger",
    examples: [{ value: "-1" }, { value: "0" }, { value: "-12678967543233" }, { value: "-100000" }],
  },
  "http://www.w3.org/2001/XMLSchema#positiveInteger": {
    label: "positive integer",
    prefixed: "xsd:positiveInteger",
    examples: [{ value: "1" }, { value: "12678967543233" }, { value: "+100000" }],
  },
  "http://www.w3.org/2001/XMLSchema#short": {
    label: "short",
    prefixed: "xsd:short",
    description: "Represents an integer that is less than or equal to 32 767 and greater than or equal to -32 768.",
    examples: [{ value: "-1" }, { value: "0" }, { value: "12678" }, { value: "+10000" }],
  },
  "http://www.w3.org/2001/XMLSchema#string": {
    label: "string",
    prefixed: "xsd:string",
    description: "Represents any character that is in the range of legal characters for XML",
    examples: [],
  },
  "http://www.w3.org/2001/XMLSchema#unsignedByte": {
    label: "unsigned byte",
    prefixed: "xsd:unsignedByte",
    description: "Represents an unsigned integer that is less than or equal to 255",
    examples: [{ value: "1" }, { value: "126" }, { value: "100" }],
  },
  "http://www.w3.org/2001/XMLSchema#unsignedInt": {
    label: "unsigned integer",
    prefixed: "xsd:unsignedInt",
    description: "Represents an unsigned integer that is less than or equal to 4 294 967 295.",
    examples: [{ value: "0" }, { value: "1267896754" }, { value: "100000" }],
  },
  "http://www.w3.org/2001/XMLSchema#unsignedLong": {
    label: "unsigned long",
    prefixed: "xsd:unsignedLong",
    description: "Represents an unsigned integer that is less than or equal to 9 223 372 036 854 775 807.",
    examples: [{ value: "0" }, { value: "12678967543233" }, { value: "100000" }],
  },
  "http://www.w3.org/2001/XMLSchema#unsignedShort": {
    label: "unsigned short",
    prefixed: "xsd:unsignedShort",
    description: "Represents an unsigned integer that is less than or equal to 65 535.",
    examples: [{ value: "0" }, { value: "12678" }, { value: "10000" }],
  },
  "http://www.w3.org/2001/XMLSchema#yearMonthDuration": {
    label: "year-month duration",
    prefixed: "xsd:yearMonthDuration",
    examples: [
      { value: "P1Y2M", textual: "1 year and 2 months" },
      { value: "-P13M", textual: "13 months" },
    ],
  },
};
